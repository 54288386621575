<template>
  <v-app style="padding-top: 20px;">
    <div>
      <!-- filters -->
      <div class="row">
        <b-form @submit="onSubmit" class="one-filters">
          <b-form-group
            id="input-group-1"
            label="Numar de locuri:"
            label-for="input-1"
            description=""
          >
            <b-form-input
              id="input-1"
              v-model="form.places"
              type="text"
              required
              placeholder="10"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-2"
            label="De la data:"
            label-for="input-2"
            description=""
          >
            <b-form-input
              id="input-2"
              v-model="form.date_start"
              type="date"
              placeholder="10.08.2021"
            ></b-form-input>
            <b-form-timepicker
              id="input-2t"
              v-model="form.time_start"
              type="time"
              minutes-step="15"
              placeholder="09:00"
            ></b-form-timepicker>
          </b-form-group>
          <b-form-group
            id="input-group-3"
            label="Pana la data:"
            label-for="input-3"
            description=""
          >
            <b-form-input
              id="input-3"
              v-model="form.date_finish"
              type="date"
              placeholder="10.08.2021"
            ></b-form-input>
            <b-form-timepicker
              id="input-3t"
              v-model="form.time_finish"
              minutes-step="15"
              type="time"
              placeholder="09:00"
            ></b-form-timepicker>
          </b-form-group>
          <b-button type="submit" variant="primary">Filtreaza</b-button>
        </b-form>
      </div>

      <!-- admin list -->
      <div v-if="this.currentUserPersonalInfo.super_admin">
        <div v-if="this.AllRoomsInfo.errors && this.AllRoomsInfo.errors['not_found'].length > 0">
          <h4 class="error-no-room">Nu am gasit o sala disponibila. Va rugam sa ne scrieti pe adresa <a href="mailto:lifestyle@theone.ro">lifestyle@theone.ro</a>. Va multumim!</h4>
        </div>
        <div v-else class="row">
          <v-card
            v-for="(room, index) in this.AllRoomsInfo.data"
            :key="index"
            class="mx-auto"
            :class="room.status < 1 ? 'card-inactive' : 'card-active'"
            max-width="400"
          >
            <v-img
              v-if="room.images.data[0]"
              class="white--text align-end"
              height="200px"
              :src="room.images.data[0]['url']"
            >
              <v-card-title>{{ room.name }}</v-card-title>
            </v-img>

            <v-img
              v-else
              class="white--text align-end"
              height="200px"
              src="media/stock-600x600/img-1.jpg"
            >
              <v-card-title>{{ room.name }}</v-card-title>
            </v-img>

            <v-card-subtitle class="pb-0">{{ room.details }}</v-card-subtitle>

            <v-card-text class="text--primary">
              <div>
                Capacitate: <strong>{{ room.places }}</strong>
              </div>

              <div v-if="room.amenities !== null">
                Dotari:
                <strong v-for="(amenity, index) in room.amenities" :key="index"
                  >{{ amenity
                  }}<span v-if="index + 1 < room.amenities.length"
                    >,
                  </span></strong
                >
              </div>
              <div>
                Status:
                <strong>
                  <span style="color: green;" v-if="room.status === 1"
                    >Activ</span
                  >
                  <span style="color: gray;" v-if="room.status === 0"
                    >Inactiv</span
                  >
                </strong>
              </div>
            </v-card-text>

            <v-card-actions>
              <router-link
                class="font-size-3 ml-2 v-btn v-btn--text theme--light v-size--default purple--text"
                :to="{ path: '/room/' + room.id }"
              >
                Selectează
              </router-link>
              <v-btn color="green" text v-if="room.status < 1">
                Activează
              </v-btn>
              <v-btn color="orange" text v-else>
                Dezactivează
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
        <!--        ADMIN: {{ this.AllRoomsInfo }}-->
      </div>
      <!-- all rooms list -->
      <div v-else>
        <!-- room list -->
        <div v-if="this.AllUsersRoomsInfo.errors && this.AllUsersRoomsInfo.errors['not_found'].length > 0">
          <h4 class="error-no-room">Nu am gasit o sala disponibila. Va rugam sa ne scrieti pe adresa <a href="mailto:lifestyle@theone.ro">lifestyle@theone.ro</a>. Va multumim!</h4>
        </div>
        <div v-else class="row">
          <v-card
            v-for="(room, index) in this.AllUsersRoomsInfo"
            :key="index"
            class="mx-auto"
            max-width="400"
          >
            <v-img
              v-if="room.images.data[0]"
              class="white--text align-end"
              height="200px"
              :src="room.images.data[0]['url']"
            >
              <v-card-title>{{ room.name }}</v-card-title>
            </v-img>

            <v-img
              v-else
              class="white--text align-end"
              height="200px"
              src="media/stock-600x600/img-1.jpg"
            >
              <v-card-title>{{ room.name }}</v-card-title>
            </v-img>

            <v-card-subtitle class="pb-0">{{ room.details }}</v-card-subtitle>

            <v-card-text class="text--primary">
              <div>
                Capacitate: <strong>{{ room.places }}</strong>
              </div>

              <div>
                Dotari:
                <strong v-for="(amenity, index) in room.amenities" :key="index"
                  >{{ amenity
                  }}<span v-if="index + 1 < room.amenities.length"
                    >,
                  </span></strong
                >
              </div>
            </v-card-text>

            <v-card-actions>
              <router-link
                class="font-size-3 ml-2 v-btn v-btn--text theme--light v-size--default purple--text"
                :to="{ path: '/room/' + room.id }"
              >
                Selectează
              </router-link>
            </v-card-actions>
          </v-card>
        </div>
      </div>
    </div>
  </v-app>
</template>

<style>
.one-filters {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  width: 90%;
  margin: 0 auto 50px;
}

.error-no-room {
  font-weight: normal;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .one-filters {
    flex-direction: column;
  }
}

.one-filters #input-group-1,
.one-filters #input-group-2,
.one-filters #input-group-3 {
  margin: 0 4px;
}

.one-filters .btn:not(#input-2t):not(#input-3t) {
  width: 120px;
  height: 40px;
  margin-top: 24px;
  margin-left: 30px;
}

.card-inactive .v-image {
  opacity: 0.4;
}

#input-group-1 {
  width: 120px;
}

#input-group-2 > div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

#input-group-2 #input-2 {
  width: 60%;
}

#input-group-2 #input-2t__outer_ {
  width: 40%;
}

#input-group-3 > div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

#input-group-3 #input-3 {
  width: 60%;
}

#input-group-3 #input-3t__outer_ {
  width: 40%;
}

.v-sheet.v-card {
  margin: 10px;
}

.v-application .purple--text {
  color: #47243c !important;
}
</style>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_ROOMS } from "@/core/services/store/room.module";
import { FILTER_ROOMS } from "@/core/services/store/room.module";

export default {
  data() {
    return {
      form: {
        places: "",
        date_start: "",
        time_start: "",
        data_finish: "",
        time_finish: ""
      }
    };
  },
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Lista salilor" }]);
    this.$store.dispatch(GET_ROOMS);
    //console.log(this.currentUserPersonalInfo);
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    ...mapGetters(["AllRoomsInfo"]),
    ...mapGetters(["AllUsersRoomsInfo"])
    // getAllRooms() {
    //   return this.AllRoomsInfo.data;
    // },
    // getAllUsersRooms() {
    //   return this.AllUsersRoomsInfo;
    // }
    // getAllRoomsForUsers() {
    //   console.log(this.AllRoomsInfo.data);
    //   return Object.entries(this.AllRoomsInfo.data)
    //     .map(item => item[1])
    //     .filter(item => item.status > 0);
    // }
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.$store.dispatch(FILTER_ROOMS, this.form);
    }
  }
};
</script>
